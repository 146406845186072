<template>
  <div class="usercenter">
    <h3 class="title">基本信息</h3>
    <Divider />
    <Row>
      <Col span="12">
        <Row>
          <Col span="3">登录名:</Col>
          <Col span="21">{{ userInfo.name }}</Col>
        </Row>
      </Col>
      <Col span="12">
        <Row>
          <Col span="3">用户名:</Col>
          <Col span="21">{{ userInfo.nickName }}</Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col span="12">
        <Row>
          <Col span="3">性别:</Col>
          <Col span="21" v-if="userInfo.sex">{{ userInfo.sex.desc }}</Col>
        </Row>
      </Col>
      <Col span="12">
        <Row>
          <Col span="3">邮箱:</Col>
          <Col span="21">{{ userInfo.email }}</Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col span="12">
        <Row>
          <Col span="3">身份证号:</Col>
          <Col span="21">{{ userInfo.pid }}</Col>
        </Row>
      </Col>
      <Col span="12">
        <Row>
          <Col span="3">手机号:</Col>
          <Col span="21">{{ userInfo.serialNumber }}</Col>
        </Row>
      </Col>
    </Row>
    <Row>
     <!-- <Col span="12">
        <Row>
          <Col span="3">有效:</Col>
          <Col span="21" v-if="userInfo.dimissionTag">{{ userInfo.dimissionTag.desc}}</Col>
        </Row>
      </Col>-->
      <Col span="12">
        <Row>
          <Col span="3">生日:</Col>
          <Col span="21">{{ userInfo.birthday }}</Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <!--<Col span="12">
        <Row>
          <Col span="3">地市:</Col>
          <Col span="21">{{ userInfo.eparchyCode.join(',')}}</Col>
        </Row>
      </Col>-->
      <Col span="12">
        <Row>
          <Col span="3">锁定:</Col>
          <Col span="21" v-if="userInfo.lock">{{ userInfo.lock.desc }}</Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <!--<Col span="12">
        <Row>
          <Col span="3">密码:</Col>
          <Col span="21">*******</Col>
        </Row>
      </Col>-->
      <Col span="12">
        <Row>
          <Col span="3">部门:</Col>
          <Col span="21" v-if="userInfo.departs">{{ departs }}</Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col span="12">
        <Row>
          <Col span="3">备注:</Col>
          <Col span="21">123{{ userInfo.remark }}</Col>
        </Row>
      </Col>
    </Row>
  </div>
</template>
<script>
const { mapActions, mapState } = window.Vuex;
export default {
  data() {
    return {
      mtree: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.portal.userInfo
    }),
    departs() {
      if (this.userInfo.departs && this.userInfo.departs.length) {
        return this.userInfo.departs.map(item => item.departName).join(',')
      }
      return ""
    }
  },
  mounted() {
    this.mountTree(0, tree => {
      this.mtree = tree;
    });
  },
  methods: {
    ...mapActions("portal", ["getEparchyTree"]),
    async mountTree(parentId = 0, success) {
      const self = this;
      const res = await this.getEparchyTree(parentId);
      success && success(res);
    },
  }
};
</script>

<style lang="less">
.usercenter {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 300;
  line-height: 17px;
  background: #fff;
  height: 100%;
  padding:16px;
  .ivu-col {
    margin: 6px 0;
  }
  .ivu-divider {
    margin: 0;
    margin-top: 8px;
  }
  .title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #6B6B6B;
  }
}
</style>
